import {Box, Container, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

interface Props {
    color: string,
    children?: any,
    className?: string,
    containerClassName?: string,
    padding?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: "40px 30px",
        transition: theme.transitions.create("padding"),
        [theme.breakpoints.only("md")]: {
            padding: "30px 20px"
        },
        [theme.breakpoints.only("sm")]: {
            padding: "20px 15px"
        },
        [theme.breakpoints.only("xs")]: {
            padding: "10px 10px"
        }
    }
}));

const SectionContainerOld: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    return (
        <div className={props.className} style={{backgroundColor: props.color}}>
            <Container className={clsx(classes.container, props.containerClassName)}>
                <Box display="flex" flexDirection="column">
                    {props.children}
                </Box>
            </Container>
        </div>
    );
};

export default SectionContainerOld;
