import React from "react";
import Layout from "../components/Layout";
import SectionContainerOld from "../components/SectionContainerOld";

const NotFoundPage = () => (
  <Layout>
    <SectionContainerOld color="white">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </SectionContainerOld>
  </Layout>
);

export default NotFoundPage;
